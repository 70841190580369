/* eslint-disable no-unused-vars */
import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { METALICA_URL, SCORPIONS_URL } from "../urls/base-urls";
import {
  mapperToAddInssInformation,
  mapperToAlterInssInformation,
} from "./inss-information-mapper";

export async function getInssInformation() {
  const pis = JSON.parse(localStorage.getItem('user-information')).pis;
  const data = {
    pis: pis,
  };
  return data;
}

// eslint-disable-next-line no-unused-vars
export async function getCategoriesESocial() {
  const resp = await axios({
    url: `${SCORPIONS_URL}/categories-esocial`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}
// eslint-disable-next-line no-unused-vars
export async function getRetention() {
  const resp = await axios({
    url: `${SCORPIONS_URL}/retention-other-source`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json"},
  });

  return resp.data;
}
export async function updateInssInformation(formData, attachments) {

  var originalData = mapperToAlterInssInformation(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToAlterInssInformation(
    formData,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${SCORPIONS_URL}/update-information-data`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      attachments,
      ServiceRequestType.DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS_UPDATE,
      null,
      changedData
    )
  );
  return resp.data;
}

export async function addInssInformation(formData, attachments) {
  const resp = await axios.post(
    `${SCORPIONS_URL}/update-information-data`,
    requestBuilder(
      mapperToAddInssInformation(formData, FormRequestAction.insert),
      TypeRequestedFlow.save,
      attachments,
      ServiceRequestType.DOCTOR_TAXES_AND_PERMIT_INFORMATION_INSS_SAVE
    )
  );
  return resp.data;
}
